import { admin2 } from './instance'

export const FindCheckInPage = async ({
  shopId,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/checkInPageSetting`,
  })
}

export const UpdateCheckInPage = async ({
  shopId,
  displaySetting,
  enableOpenDoor,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/checkInPageSetting`,
    data: {
      displaySetting,
      enableOpenDoor,
    },
  })
}

export const GetBranchCheckInLink = async ({
  shopId,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/branchCheckInLink/findBranch`,
  })
}

export const UpsertBranchCheckInLink = async ({
  shopId,
  BranchId,
  appointmentServiceIds,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/branchCheckInLink/upsertByBranch`,
    params: {
      BranchId,
    },
    data: {
      appointmentServiceIds,
    },
  },
  )
}
