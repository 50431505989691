<template>
  <section v-loading="loading" class="section-block">
    <SectionTitle title="時段欄位設定" @edit="modal.edit = true" />
    <div>
      <el-form
        v-model="formData"
        label-position="left"
        label-width="200px"
      >
        <el-form-item label="欄位顯示名稱" prop="timeFieldName">
          <div class="secret-text collapse">
            <span>{{ formData.timeFieldName }}</span>
          </div>
        </el-form-item>
        <el-form-item label="顯示預約結束時間" prop="displayEndTime">
          <el-switch
            v-model="formData.displayEndTime"
            class="switch-input"
            active-text="是"
            inactive-text="否"
            @change="$emit('updateCheckInPage', 'displayEndTime', formData.displayEndTime)"
          />
        </el-form-item>
        <p v-if="formData.displayEndTime" class="text-sub text-gray-80">如選擇顯示預約結束時間，當服務項目有另外設定顯示時間，報到頁面將會顯示該時間，您可前往 預約模組 的 服務項目設定 確認。</p>
      </el-form>
    </div>
    <TimeRangeNameEditModal
      v-if="modal.edit"
      :data="computedFormData"
      @close="modal.edit = false"
      @refresh="$emit('refresh')"
    />
  </section>
</template>

<script>
import { defineComponent, reactive, ref, watch, computed } from 'vue'
import TimeRangeNameEditModal from './TimeRangeNameEditModal.vue'
import { get } from 'lodash'

export default defineComponent({
  name: 'TimeRangeNameSetting',
  components: {
    TimeRangeNameEditModal,
  },
  props: {
    content: { type: Object, default: () => ({}) },
  },
  setup (props) {
    const loading = ref(null)
    const formData = reactive({
      timeFieldName: '',
      displayEndTime: false,
    })
    const modal = reactive({
      edit: false,
    })
    const computedFormData = computed(() => get(props.content, 'displaySetting'))

    const syncData = async () => {
      const newFormData = JSON.parse(JSON.stringify(get(props.content, 'displaySetting')))
      formData.timeFieldName = newFormData.timeFieldName
      formData.displayEndTime = newFormData.displayEndTime
    }
    watch(props, async () => {
      await syncData()
    })
    return {
      modal,
      formData,
      loading,
      computedFormData,
    }
  },
})
</script>
