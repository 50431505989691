<template>
  <BaseDialog
    title="編輯可報到的服務項目"
    :btn1Loading="loading"
    @close="$emit('close')"
    @cancel="$emit('close')"
    @confirm="onConfirm"
  >
    <div>
      <p class="pb-[8px] text-gray-80 text-sm">有預約下列服務項目的會員，掃描門市條碼即可完成報到。</p>
      <el-form ref="formRef" :model="formData" :rules="formRules">
        <el-form-item prop="services">
          <BaseSearch
            :value.sync="formData.services"
            :api="apiFormatAdaptor(GetService)"
            multiple
            placeholder="請選擇分店"
          />
        </el-form-item>
      </el-form>
    </div>
  </BaseDialog>
</template>

<script>
import { defineComponent, computed, onBeforeMount, ref } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { useBaseForm } from '@/use/useForm'
import { UpsertBranchCheckInLink } from '@/api/checkInPage'
import { useShop } from '@/use/shop'
import BaseSearch from '@/components/Search/BaseSearch.vue'
import { GetService } from '@/api/service'
import { apiFormatAdaptor } from '@/utils/api'
import { map, get } from 'lodash'
import notifyMessage from '@/config/notifyMessage'

export default defineComponent({
  name: 'EditBranchStoreAllowCheckInServicesModal',
  components: {
    BaseDialog,
    BaseSearch,
  },
  props: {
    branchStoreData: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {
    const { shopId } = useShop()
    const { formRef, formData, initFormData, loading } = useBaseForm()
    initFormData({
      services: [],
    })
    const displayData = computed(() => {
      return {}
    })
    const formRules = computed(() => {
      const rules = {
        services: [],
      }

      return rules
    })

    const onConfirm = async () => {
      if (loading.value) return
      loading.value = true

      const [, err] = await UpsertBranchCheckInLink({
        shopId: shopId.value,
        BranchId: props.branchStoreData.id,
        appointmentServiceIds: map(formData.services, 'id'),
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success(notifyMessage.updateSuccess)
      emit('refresh')
      emit('close')
    }

    onBeforeMount(() => {
      formData.services = get(props.branchStoreData, 'edit.AppointmentServices')
    })

    return { displayData, formRef, formData, formRules, onConfirm, loading, GetService, apiFormatAdaptor }
  },
})
</script>

<style lang="postcss" scoped>

</style>
