<template>
  <div class="indoor-control-parameters">
    <PageTitle
      title="報到頁面設定"
      icon="chevron_left"
      hideBtn
      style="margin-bottom: 64px"
      @iconClick="$router.push({ name: 'Parameters' })"
    />
    <TimeRangeNameSetting v-loading="loading" :content.sync="data" @updateCheckInPage="updateCheckInPage" @refresh="refresh" />
    <CheckInSetting v-loading="loading" :content.sync="data" @updateCheckInPage="updateCheckInPage" />
    <ContentShowSetting v-loading="loading" :content.sync="data" @updateCheckInPage="updateCheckInPage" />
    <BranchLink />
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, ref } from 'vue'
import PageTitle from '@/components/Title/PageTitle'
import { FindCheckInPage, UpdateCheckInPage } from '@/api/checkInPage'
import TimeRangeNameSetting from './components/TimeRangeNameSetting'
import CheckInSetting from './components/CheckInSetting'
import ContentShowSetting from './components/ContentShowSetting'
import BranchLink from './components/BranchLink'
import store from '@/store'
import { get } from 'lodash'
export default defineComponent({
  name: 'CheckInPage',
  components: { PageTitle, TimeRangeNameSetting, CheckInSetting, ContentShowSetting, BranchLink },
  setup (props) {
    const shopId = computed(() => store.getters.shop)
    const loading = ref(null)
    const data = ref({})

    const getCheckInPage = async () => {
      loading.value = true
      const [res, err] = await FindCheckInPage({
        shopId: shopId.value,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      data.value = res
    }
    const updateCheckInPage = async (key, value) => {
      if (key !== 'enableOpenDoor') data.value.displaySetting[key] = value
      loading.value = true
      const [res, err] = await UpdateCheckInPage({
        shopId: shopId.value,
        displaySetting: get(data.value, 'displaySetting'),
        enableOpenDoor: key === 'enableOpenDoor' ? value : undefined,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('更新成功!')
      data.value = res
    }
    const refresh = async () => {
      await getCheckInPage()
    }
    onMounted(async () => {
      await refresh()
    })
    return {
      data,
      loading,
      updateCheckInPage,
      refresh,
    }
  },
})
</script>

  <style scoped lang="postcss">
  .parameters-category-list {
    @apply grid grid-cols-3 gap-[44px];
  }

  @media screen and (max-width: 1200px) {
    .parameters-category-list {
      @apply grid grid-cols-2;
    }
  }

  .empty-block {
    @apply flex flex-col w-full justify-center items-center leading-[30px] p-[20px] text-[14px] mt-[160px];
  }

  .content {
    @apply text-center text-sub leading-normal tracking-[1px] text-gray-100 mt-[8px];
  }
  </style>
