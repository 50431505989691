import { Message } from 'element-ui'

export const getDataFromApi = async (api, { payload, handler }) => {
  const [res, err] = await api(payload)
  if (err) Message.error(err)
  if (handler) await handler(res)
  return res
}

export const apiFormatAdaptor = (api) => {
  return async (payload) => {
    let res = null
    let err = null
    try {
      res = await api(payload)
    } catch (error) {
      err = error
    }

    return [res, err]
  }
}
