<template>
  <section class="section-block">
    <SectionTitle title="門市連結資訊" hint="需至「基本參數設定 > 門市設定」完成門市資訊設定後，即可產生門市連結。" hideBtn />
    <BranchLinkTable
      :content="data"
      :loading="loading"
      @upsertLink="upsertLink"
      @copyLink="copyLink"
      @refresh="getBranchCheckInLink"
    />
  </section>
</template>

<script>
import copy from 'clipboard-copy'
import SectionTitle from '@/components/Title/SectionTitle.vue'
import BranchLinkTable from './BranchLinkTable.vue'
import store from '@/store'
import { get } from 'lodash'
import { GetBranchCheckInLink, UpsertBranchCheckInLink } from '@/api/checkInPage'
import { defineComponent, onMounted, ref, computed } from 'vue'
import notifyMessage from '@/config/notifyMessage'

export default defineComponent({
  name: 'BranchLink',
  components: {
    SectionTitle,
    BranchLinkTable,
  },
  props: {
    content: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {
    const loading = ref(false)
    const shopId = computed(() => store.getters.shop)
    const data = ref([])
    const getBranchCheckInLink = async () => {
      loading.value = true
      const [res, err] = await GetBranchCheckInLink({
        shopId: shopId.value,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      data.value = res
      loading.value = false
    }
    const upsertLink = async (row) => {
      const [, err] = await UpsertBranchCheckInLink({
        shopId: shopId.value,
        BranchId: get(row, 'id'),
      })
      if (err) {
        window.$message.error(err)
        return
      }
      await getBranchCheckInLink()
    }
    const copyLink = (row) => {
      const link = get(row, 'link')
      copy(link)
      window.$message.success(notifyMessage.copySuccess)
    }
    onMounted(async () => {
      await getBranchCheckInLink()
    })

    return { data, upsertLink, copyLink, loading, getBranchCheckInLink }
  },
})
</script>
