<template>
  <div>
    <el-table v-loading="loading" :data="tableData" class="mt-[16px]">
      <EmptyBlock slot="empty" />
      <el-table-column label="門市名稱" prop="name" align="center" />
      <el-table-column label="可報到的服務項目" prop="bindingServices" align="center" />
      <el-table-column label="門市連結" prop="link" align="center">
        <template slot-scope="scope">
          <p v-if="scope.row.link" class="text-gray-100">{{ scope.row.link }}</p>
          <p v-else class="noLink">系統尚未產生連結，請先點擊 產生連結 按鈕</p>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" align="center" width="200">
        <template slot-scope="scope">
          <div class="flex justify-center items-center" style="gap: 8px">
            <a v-if="scope.row.edit" class="text-primary-100 cursor-pointer underline" @click="copyLink(scope.row)">複製連結</a>
            <a v-else class="text-primary-100 cursor-pointer underline" @click="upsertLink(scope.row)">產生連結</a>
            <a class="text-primary-100 cursor-pointer underline" @click="onEdit(scope.row)">編輯</a>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <EditBranchStoreAllowCheckInServicesModal
      v-if="modal.edit"
      :branchStoreData="selectRow"
      @close="modal.edit = false"
      @refresh="$emit('refresh')"
    />
  </div>
</template>

<script>
import { get, map, join } from 'lodash'
import { defineComponent, computed, reactive, ref } from 'vue'
import EditBranchStoreAllowCheckInServicesModal from './EditBranchStoreAllowCheckInServicesModal.vue'

export default defineComponent({
  name: 'BranchLinkTable',
  components: {
    EditBranchStoreAllowCheckInServicesModal,
  },
  props: {
    content: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false },
  },
  setup (props, { emit }) {
    const modal = reactive({
      edit: false,
    })
    const selectRow = ref(null)
    const tableData = computed(() => {
      return map(props.content, (item) => {
        const services = map(get(item, 'BranchCheckInLink.AppointmentServices', []), (i) => i.name)
        return {
          id: get(item, 'Branch.id'),
          bindingServices: services.length ? join(services, '、') : '-',
          name: get(item, 'Branch.name'),
          link: get(item, 'BranchCheckInLink') ? get(item, 'BranchCheckInLink.link') : false,
          edit: get(item, 'BranchCheckInLink'),
        }
      })
    })
    const upsertLink = (row) => {
      emit('upsertLink', row)
    }
    const copyLink = (row) => {
      emit('copyLink', row)
    }
    const onEdit = (row) => {
      selectRow.value = row
      modal.edit = true
    }
    return { tableData, upsertLink, copyLink, onEdit, modal, selectRow }
  },
})
</script>
<style lang="postcss" scoped>
.noLink {
  @apply text-gray-50
}
</style>
